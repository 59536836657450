/* Add this to your CSS file */
.hero-section {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  
  .container {
    position: relative;
    z-index: 9;
  }
  
  .title {
    font-size: 4rem;
    font-weight: bold;
    color: white;
  }

  .subtitle {
    font-size: 2rem;
    color: white;
  }
  
  p {
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .about-content p {
    font-size: 1rem;
    margin: 10px 0;
  }

  .ventures-content {
    font-size: 1rem;
    margin: 10px 0 40px;
    color: white;
  }
  
  button {
    background-color: #FDF7E9;
    color: white;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: white;
    color: #FDF7E9;
  }
  